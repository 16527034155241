<template>
  <div id="app_content">
    <InfiniteLoader v-if="isLoading" />

    <template v-else>
      <div id="wrapper">
        <header id="main_header">
          <div
            id="auth"
            :class="{ logged: user }"
          >
            <template v-if="!user">
              <button
                type="button"
                @click="openModal('login')"
              >
                Connexion
              </button>
              <hr />
              <button
                type="button"
                @click="openModal('register')"
              >
                Inscription
              </button>
            </template>
            <template v-else>
              <p>Bonjour {{ user.first_name }}</p>
              <router-link :to="{ name: 'profile' }">
                <img
                  v-if="user?.avatar"
                  :src="user.avatar"
                  alt=""
                  role="presentation"
                />
                <fa-icon
                  v-else
                  icon="fa-solid fa-circle-user"
                />
                <p class="tooltip tooltip-bottom-center">Mon profil</p>
              </router-link>
              <hr />
              <button
                id="logout"
                type="button"
                @click="store.dispatch('logout')"
              >
                <fa-icon icon="fa-solid fa-right-from-bracket" />
                <p class="tooltip tooltip-bottom-left">Se déconnecter</p>
              </button>
            </template>
          </div>
          <div id="main_title">
            <router-link :to="{ name: 'home' }">
              <h1>Les Tent'Actrices</h1>
            </router-link>
            <h2>Troupe de théâtre</h2>
          </div>
          <a
            id="facebook_link"
            href="https://www.facebook.com/LesTentActrices/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <fa-icon icon="fa-brands fa-facebook" />
          </a>
        </header>

        <div
          v-if="screenSizeIsSmall"
          id="menu_burger"
          class="menu"
        >
          <div v-click-outside="() => toggleMenu = false">
            <button
              type="button"
              @click="toggleMenu = !toggleMenu"
            >
              <fa-icon icon="fa-solid fa-bars" />
              Menu
            </button>
            <transition name="menu-slide">
              <nav
                v-if="toggleMenu"
                @click="toggleMenu = false"
              >
                <router-link
                  v-for="route in routesInMenu"
                  :key="route.name"
                  :to="{ name: route.name }"
                  :class="{ 'ticketing': route.name === 'ticketing' }"
                >
                  {{ route.meta.title }}
                </router-link>
              </nav>
            </transition>
          </div>
        </div>
        <nav
          v-else
          id="menu_large"
          class="menu"
        >
          <router-link
            v-for="route in routesInMenu"
            :key="route.name"
            :to="{ name: route.name }"
            :class="{ 'ticketing': route.name === 'ticketing' }"
          >
            {{ route.meta.title }}
          </router-link>
        </nav>

        <main>
          <h2
            v-if="pageTitle"
            class="title"
          >
            {{ pageTitle }}
          </h2>
          <router-view />
        </main>
      </div>

      <footer>
        <div>
          <router-link :to="{ name: 'legal_notice' }">
            Mentions légales
          </router-link>
          <small>© {{ dayjs().year() }} - Les Tent'Actrices</small>
        </div>
      </footer>

      <ModalLogin key="login" />
      <ModalRegister key="register" />
      <ModalForgotPassword key="forgot_password" />
    </template>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { openModal } from "@/modules/modale";

import InfiniteLoader from "@/components/InfiniteLoader";
import ModalLogin from "@/components/modales/ModalLogin.vue";
import ModalRegister from "@/components/modales/ModalRegister.vue";
import ModalForgotPassword from "./components/modales/ModalForgotPassword.vue";

const dayjs = require("dayjs");

const store = useStore();
const router = useRouter();

const isLoading = ref(false);
const toggleMenu = ref(false);
const windowWidth = ref(window.innerWidth);

const routesInMenu = reactive(router.options.routes.filter((route) => route.meta.showInMenu));

const { user, pageTitle } = {
  user: computed(() => store.getters.user),
  pageTitle: computed(() => store.getters.pageTitle),
};

const screenSizeIsSmall = computed(() => windowWidth.value <= 576);

onBeforeMount(() => {
  isLoading.value = true;
  store.dispatch("verifyToken").then(() => isLoading.value = false);
});

onMounted(() => {
  // Pour adapter la taille de l'écran
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});
</script>

<style lang="scss">
@use "@/assets/styles/_global.scss";
@use "@/assets/styles/_app.scss";
@use "@/assets/styles/_toast.scss";
</style>
